import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import {
  prefix,
  loginRedirect,
  notify,
} from '../../../utils/index_tagging_tool'

import { api } from '../../../utils/index'

import { saveAs } from 'file-saver'

export default function ExcelDownload({ dim, loader }) {

  const [loading, setLoading] = useState(false)

  const handleExport = async (e) => {
    e.preventDefault()
    setLoading(true)

    notify(
      "Stiamo generando l'export, potrebbe volerci qualche minuto.",
      'warning'
    )

    api(
      prefix +
        `rule-file-download/${dim}/`,
      { responseType: 'blob' }
    )
      .then(loginRedirect)
      .then((data) => {
        saveAs(data, `${dim}_export.xlsx`)
        notify('File generato con successo', 'success')
      })
      .catch((e) => {
        notify(e.message, 'error')
      })
      .finally(() => setLoading(false))
  }

  return (
    <>
      {loading && (
         <div className="spinner-border" role="status">
           <span className="sr-only">Loading...</span>
         </div>
      )}
      <Button
        onClick={(e) => handleExport(e)}
        variant="warning"
        className={'btn btn-info mr-2'}
        disabled={loader}
      >
        Esporta
      </Button>
    </>
  )
}
