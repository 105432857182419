import React, { useState } from 'react'
import { Form } from 'react-bootstrap'

// Componente generico usato per rappresentare il "filtro" della tabella (aka un texbox tale per cui se scrivi "AA" ti ritorna solo le regole che hanno "AA" dentro)
// Di solito è in alto nella tabella, appena sotto il nome di una colonna
export default function DetailFilter({ filter, setFilter }) {
  const [value, setValue] = useState(filter)

  const updateValue = (e) => {
    e.preventDefault()
    setValue(e.target.value)
    setFilter(e.target.value)
  }

  return (
    <fieldset className="w-seo-control-field">
      <Form.Control
        type="text"
        className="form-control"
        value={value}
        onChange={updateValue}
      />
    </fieldset>
  )
}
