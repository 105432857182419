import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Link, useParams } from 'react-router-dom'
import Nome from './FormFields/Nome'
import Email from './FormFields/Email'
import Motori from './FormFields/Motori'
import Domini from './FormFields/Domini'
import Brand from './FormFields/Brand'
import Country from './FormFields/Country'
import Industry from './FormFields/Industry'
import Mobile from './FormFields/Mobile'
import Authority from './FormFields/Authority'
import File from './FormFields/File'
import Validity from './FormFields/Validity'
import Volume from './FormFields/Volume'
import Formula from './FormFields/Formula'
import FormulaMonth from './FormFields/FormulaMonth'
import Competitor from './FormFields/Competitor'
import ShortReport from './FormFields/ShortReport'
import MultiplePositioning from './FormFields/MultiplePositioning'
import LocationName from './FormFields/LocationName'
import Description from './FormFields/Description'
import { notify } from '../utils/notify'
import { api } from '../utils/index'
import { useFetch } from '../hooks'
import WModal from "../components/molecules/WModal";
import CharCheckModal from "../components/molecules/CharCheckModal";
import SetTitle from '../utils/set_title'
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	Form,
	Row,
	Col
} from "reactstrap";
import UserHeader from "../components/Headers/StudioKwHeader.js";

const AddContext = React.createContext()

export default function AddStudiokw() {
	SetTitle('Add StudioKeyword')
	// Parametri fields
	const [name, setName] = useState('')
	const [emails, setEmails] = useState([])
	const [motoreRicerca, setMotoreRicerca] = useState('google.it')
	const [domini, setDomini] = useState([])
	const [brand, setBrand] = useState('')
	const [country, setCountry] = useState('')
	const [industry, setIndustry] = useState('')
	const [mobile, setMobile] = useState(true)
	const [validity, setValidity] = useState(7)
	const [authority, setAuthority] = useState(false)
	const [file, setFile] = useState(null)
	const [fileName, setFileName] = useState('')
	const [link, setLink] = useState('')
	const [bigqueryLink, setBigqueryLink] = useState('')
	const [volume, setVolume] = useState('')
	const [formula, setFormula] = useState('FormulaStatica_ctr')
	const [month, setMonth] = useState('')
	const [competitor, setCompetitor] = useState(false)
	const [short, setShort] = useState(false)
	const [multiplePositioning, setMultiplePositioning] = useState(false)
	const [bigQuery, setBigQuery] = useState(true)
	const [locationName, setLocationName] = useState('')
	const [term, setTerm] = useState('')
	const [locationFiltered, setLocationFiltered] = useState([])
	const [description, setDescription] = useState('')
	const [allBrand, setAllBrand] = useState([])

  	let history = useHistory()

	// Recupero location names da backend
	const { data: dataLocationNames } = useFetch({ url: 'location-names/' })

	// Recupero motori ricerca da backend
	const [motoriRicerca, setMotoriRicerca] = useState([])
	const getMotori = () => {
		api
			.get('motori-ricerca/')
			.then((res) => {
				setMotoriRicerca(res.data)
			})
			.catch((err) => console.error(err))
	}

	// url param
	let { id: studioKwID } = useParams()
	// studioKw detail
	const [studioKwDetail, setStudioKwDetail] = useState({})

	// Recupero formula serpy da backend
	const [formulas, setFormulas] = useState([])
	const [formulaMonths, setFormulaMonths] = useState({})
	const [formulaAll, setFormulaAll] = useState({})
	// Modal e costi da mostrare
	const [show, setShow] = useState(false)
	const [price, setPrice] = useState({ min: 0, max: 0 })
	// Modal check caratteri
	const [showCharCheck, setShowCharCheck] = useState(false)
	const [errCharList, setErrCharList] = useState([])
	const [acceptedCharCheck, setAcceptedCharCheck] = useState(false)

	const getFormulas = () => {
		api
			.get('formulas-serpy/')
			.then((res) => {
				const cache = {}
				res.data.forEach((f) => {
					if (cache.hasOwnProperty(f.category)) {
						cache[f.category].push(f.giorno.split('T')[0])
					} else cache[f.category] = [f.giorno.split('T')[0]]
				})
				setFormulas(Object.keys(cache))
				setFormulaMonths(cache)
				setFormulaAll(res.data)
			})
			.catch((err) => {
				console.error(err)
				notify('Errore nel caricamento formule da Serpy', 'error')
			})
	}

	const getStudioKwDetail = (id) => {
		api
			.get(`studiokw/${id}/`)
			.then((response) => {
				setStudioKwDetail(response)
			})
			.catch((error) => console.error(error))
	}

	const getAllBrand = () => {
		api
			.get(`get_all_brand/`)
			.then((response) => {
				setAllBrand(response.data)
			})
			.catch((error) => console.error(error))
	}

	const filterLocationNames = (nameSearched = '') => {
		if (!!dataLocationNames && nameSearched.length > 2) {
			let list = dataLocationNames.filter(
				(location) => location.toLowerCase().includes(nameSearched.toLowerCase()) === true
			)
			setLocationFiltered(list)
		}
	}

	useEffect(() => {
		getMotori()
		getFormulas()
		getAllBrand()
	}, [])

	useEffect(() => {
		studioKwID && getStudioKwDetail(studioKwID)
	}, [])

  	useEffect(() => {
		if (Object.keys(studioKwDetail).length !== 0) {
		const {
			nome,
			brand,
			email,
			motore_ricerca,
			domini,
			country,
			industry,
			mobile,
			day_delta,
			authority_labs,
			file,
			file_name,
			link,
			bigquery_link,
			volume_column,
			formula_json,
			competitor_discovery,
			short_report,
			multiple_positioning,
			export_to_bigquery,
			description,
			location_name,
			term,
		} = studioKwDetail?.data

		const motore = motoriRicerca.find((m) => m.id === motore_ricerca)
		let dateMese = formula_json.giorno.toString().split('T')[0]
		setName(window.location.pathname.includes('duplicate') ? `${nome} - COPIA` : nome)
		setBrand(brand)
		setEmails(email.split(',') )
		setMotoreRicerca(motore?.nome ?? 'google.it')
		setDomini(domini.split(','))
		setDescription(description)
		setLocationName(location_name)
		setTerm(term)
		setCountry(country)
		setIndustry(industry)
		setMobile(mobile ? true : false)
		setValidity(day_delta)
		setAuthority(authority_labs)
		setFile(file)
		setFileName(file_name)
		setLink(link)
		setBigqueryLink(bigquery_link)
		setVolume(volume_column)
		setFormula(formula_json.category)
		setMonth(dateMese)
		setCompetitor(competitor_discovery)
		setShort(short_report)
		setMultiplePositioning(multiple_positioning)
		setBigQuery(export_to_bigquery)
		}

	}, [studioKwDetail])

	useEffect(() => {
		// Se ho la lista dei mesi delle formule, procedo
		Object.keys(formulaMonths).length > 0 && !studioKwID && setMonth(formulaMonths[formula][0])
	}, [formula, formulaMonths])

	const handleClear = () => {
		setName('')
		setEmails([])
		setMotoreRicerca('google.it')
		setDomini([])
		setBrand('')
		setCountry('')
		setIndustry('')
		setMobile(true)
		setValidity(7)
		setAuthority(false)
		setFile(null)
		setFileName('')
		setLink('')
		setBigqueryLink('')
		setVolume('')
		setFormula('FormulaStatica_ctr')
		setMonth('')
		setCompetitor(false)
		setShort(false)
		setMultiplePositioning(false)
		setBigQuery(true)
		setLocationName('')
		setTerm('')
		setDescription('')
		setEmails([])
		setDomini([])
	}

	const handleSubmit = (e) => {
		e.preventDefault()

		let formData = new FormData()
		formData.append('nome', name)
		formData.append('file_name', file?.name ? file.name : fileName)
		formData.append('day_delta', validity)

		const motore = motoriRicerca.find((m) => m.nome === motoreRicerca)['id']
		formData.append('motore_ricerca', motore)
		let f = formulaAll.find((f) => f.category === formula && f.giorno.split('T')[0] === month)
		if (formula === "FormulaStatica_ctr" || formula === "FormulaStatica_StimeAumentoTraffico") {
			f = formulaAll.find((f) => f.category === formula)
		}
		formData.append('formula_json', JSON.stringify(f))

		formData.append('mobile', mobile)
		formData.append('short_report', short)
		formData.append('authority_labs', authority)
		formData.append('multiple_positioning', multiplePositioning)
		formData.append('export_to_bigquery', bigQuery)
		formData.append('competitor_discovery', competitor)
		brand && formData.append('brand', brand)
		industry && formData.append('industry', industry)
		country && formData.append('country', country)
		formData.append('description', description)
		formData.append('volume_column', volume ? volume : 'Volume')
		formData.append('location_name', locationName)
		formData.append('term', term)
		formData.append('email', emails)
		formData.append('domini', domini)
		formData.append('file', file ?? null)

		api
			.post('studiokw/', formData, {
				headers: { 'Content-Type': 'multipart/form-data' },
			})
			.then((response) => {
				if (response.status === 201) {
					window.location = '/home'
				} else {
					notify(
						'Non è stato possibile creare lo studiokw, riprovare o contattare un Innovation',
						'error'
					)
				}
			})
			.catch((err) => {
				notify(
					err.message,
					'error'
				)
				console.error(err.message)
			})
	}

	const updateStudioKW = (e) => {
		e.preventDefault()

		let f = formulaAll.find((f) => f.category === formula && f.giorno.split('T')[0] === month)
		if (formula === "FormulaStatica_ctr" || formula === "FormulaStatica_StimeAumentoTraffico") {
			f = formulaAll.find((f) => f.category === formula)
		}
		let update_data = {
			...studioKwDetail.data,
			"nome": name,
			"brand": brand,
			"country": country,
			"industry": industry,
			"domini": domini.toString(),
			"email": emails.toString(),
			"mobile": mobile,
			"description": description,
			"multiple_positioning": multiplePositioning,
			"day_delta": validity,
			"volume_column": volume,
			"competitor_discovery": competitor,
			"formula_json": f,
			"short_report": short,
			"authority_labs": authority,
			"location_name": locationName,
			"term":term,
			"export_to_bigquery": bigQuery,
			"file_output_name": fileName,
		}

		delete update_data.file
		delete update_data.file_output

		api.patch('studiokw/' + studioKwID + '/', update_data, {
			headers: { 'Content-Type': 'application/json' },
		})
			.then((response) => {
				if (response.status === 200) {
					notify('Modifiche applicate con successo.', 'success')
					window.location = '/home'
				}
			},
				(err) => {
					notify(
						err.message,
						'error'
					)
				})
	}

	const getStudioKwCosts = (filename) => {
		api.post('api/1/get-studiokw-cost/', { filename: filename }, {
			headers: { 'Content-Type': 'application/json' },
		}).then((res) => {
			if (res.status >= 400) {
				throw new Error('Impossibile recuperare i dati di costo')
			}
			return res.data
		}).then(
			(data) => {
				setPrice({ min: data.cost_min, max: data.cost_max })
				setShow(true)
			},
			(err) => {
				notify(err.message, 'warning')
			}
		)
	}

	const uploadFile = () => {
		let formData = new FormData()
		formData.append('volume_column', volume ? volume : 'Volume')
		file && formData.append('file', file)
		acceptedCharCheck && formData.append('accepted', acceptedCharCheck)

		return api.post('studiokw/upload_file/', formData, {
			headers: { 'Content-Type': 'multipart/form-data' },
		}).then((res) => {
			setFileName(res.data)
			return res.data
		})
		.catch((err) => {
			if (err.response.data?.message.includes('ERROR Encoding keywords')) {
				setErrCharList(err.response.data?.errors);
				setShowCharCheck(true);
			}
			else {
				notify(err.response.data.message, 'error')
				throw Error(err.response.data.message)
			}

			return err
		})
	}

	/***
		* Opens the price modal and retrieves informations
		*/
	const fileEvaluation = (e) => {
		if (e)
			e.preventDefault()
		if (emails.length === 0) {
			notify("Compilare il campo Email!", "warning")
			return
		}
		if (domini.length === 0) {
			notify("Inserire almeno un dominio!", "warning")
			return
		}
		uploadFile().then((filename) => getStudioKwCosts(filename), (err) => setShow(false))
	}

	const confirmEvaluation = (e) => {
		e.preventDefault()
		setShowCharCheck(false)
		setAcceptedCharCheck(true)
	}

	useEffect(() => {
		if(acceptedCharCheck) {
			fileEvaluation()
		}
	}, [acceptedCharCheck])

	return (
		<>
			<UserHeader />
			<Row>
				<Col style={{display: "flex", justifyContent: "center"}}>
					<Card style={{ marginTop: '+3em', maxWidth:'1200px', marginBottom: "150px" }} className="bg-secondary shadow">
						<CardHeader className="bg-white border-0">
							<Row className="align-items-center">
								<Col md={10}>
									<h1> Add StudioKeyword </h1>
								</Col>
								<Col md={2} style={{textAlign: "right"}}>
									<Button color="warning" onClick={() => history.push('/')}>&#60;- Go Back</Button>
								</Col>
							</Row>
						</CardHeader>
						<CardBody className="pt-0 pt-md-4">
							<div id="AddStudiokw">
								<CharCheckModal show={showCharCheck} onHide={setShowCharCheck} onSubmit={confirmEvaluation} errCharList={errCharList} />
								<WModal show={show} onHide={setShow} cost={price} onSubmit={handleSubmit} />
								<Form onSubmit={ studioKwID && !window.location.pathname.includes('duplicate') ? updateStudioKW : typeof(file)=='string' ? handleSubmit : fileEvaluation }>
									<AddContext.Provider
										value={{
											name: name,
											setName: setName,
											emails: emails,
											setEmails: setEmails,
											motoreRicerca: motoreRicerca,
											setMotoreRicerca: setMotoreRicerca,
											motoriRicerca: motoriRicerca,
											domini: domini,
											setDomini: setDomini,
											country: country,
											setCountry: setCountry,
											industry: industry,
											setIndustry: setIndustry,
											mobile: mobile,
											setMobile: setMobile,
											validity: validity,
											setValidity: setValidity,
											authority: authority,
											setAuthority: setAuthority,
											file: file,
											fileName: fileName,
											link: link,
											setFile: setFile,
											setFileName: setFileName,
											volume: volume,
											setVolume: setVolume,
											formula: formula,
											setFormula: setFormula,
											formulas: formulas,
											formulaMonths: formulaMonths,
											month: month,
											setMonth: setMonth,
											competitor: competitor,
											setCompetitor: setCompetitor,
											short: short,
											setShort: setShort,
											multiplePositioning: multiplePositioning,
											setMultiplePositioning: setMultiplePositioning,
											bigQuery: bigQuery,
											setBigQuery: setBigQuery,
											locationName: locationName,
											setLocationName: setLocationName,
											term: term,
											setTerm: setTerm,
											filterLocationNames: filterLocationNames,
											locationFiltered: locationFiltered,
											setDescription: setDescription,
											description: description,
											brand: brand,
											setBrand: setBrand,
											allBrand: allBrand
										}}
									>
										<h6 className="heading-small text-muted mb-4">
											Info Base
										</h6>
										<Row>
											<Col xs={12} md={6}>
												<Nome context={AddContext} />
											</Col>
											<Col xs={12} md={6}>
												<Email context={AddContext} />
											</Col>
										</Row>
										<Row>
											<Col xs={12} md={6}>
												<Motori context={AddContext} />
											</Col>
											<Col xs={12} md={6}>
												<Domini context={AddContext} />
											</Col>
										</Row>
										<Row>
											<Col xs={12} md={4}>
												<Brand context={AddContext} />
											</Col>
											<Col xs={12} md={4}>
												<Industry context={AddContext} />
											</Col>
											<Col xs={12} md={4}>
												<Country context={AddContext} />
											</Col>
										</Row>
										<Row>
											<Col xs={12} md={5}>
												<LocationName context={AddContext} />
											</Col>
											<Col md="auto"></Col>
											<Col xs={12} md={6}>
												<Description context={AddContext} />
											</Col>
										</Row>
										<hr className="my-4"></hr>
										<h6 className="heading-small text-muted mb-4">
											SERP
										</h6>
										<Row>
											<Col xs={12} md={6}>
												<Mobile context={AddContext} />
											</Col>
											<Col xs={12} md={6}>
												<Validity context={AddContext} />
											</Col>
										</Row>
										<Row>
											<Col xs={12} md={6}>
												<Formula context={AddContext} />
											</Col>
											<Col xs={12} md={6}>
												<FormulaMonth context={AddContext} />
											</Col>
										</Row>
										<Row>
											<Col xs={12} md={6}>
												<Authority context={AddContext} />
											</Col>
										</Row>
										<hr className="my-4"></hr>
										<h6 className="heading-small text-muted mb-4">
											IMPORT
										</h6>
										<Row>
											<Col xs={12} md={6}>
												<Volume context={AddContext} />
											</Col>
											<Col xs={12} md={6}>
												<File context={AddContext} />
											</Col>
										</Row>
										<hr className="my-4"></hr>
										<h6 className="heading-small text-muted mb-4">
											EXPORT
										</h6>
										<Row>
											<Col xs={12} md={6}>
												<Competitor context={AddContext} />
											</Col>
											<Col xs={12} md={6}>
												<ShortReport context={AddContext} />
											</Col>
										</Row>
										<Row>
											<Col xs={12} md={6}>
												<MultiplePositioning context={AddContext} />
											</Col>
										</Row>
										{(bigqueryLink && bigqueryLink.length > 0) ? 
											<Row>
												<Col xs={12} md={6}>
													<a href={bigqueryLink} target="_blank">Link a RAW_Export (Bigquery)</a> 
												</Col>
											</Row>
										: null}
										<Row style={{ marginBottom: '3%', marginTop: '3%' }}>
											<Col xs={12} md={2}>
												<Link to="/home">
													<Button style={{ width: '100%' }}>Back</Button>
												</Link>
											</Col>
											<Col xs={12} md={4} />
											<Col xs={12} md={2}>
												<Button style={{ width: '100%' }} onClick={handleClear}>
													Clear
												</Button>
											</Col>
											<Col xs={12} md={4}>
											<Button color="info" size="lg" >Submit Report</Button>
											</Col>
										</Row>
									</AddContext.Provider>
								</Form>
							</div>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</>
	)
}
